import React from 'react';
import styled, { css } from 'styled-components';
import { screen } from '..';
export const Table = ({ mb, truncateHeaders, fixedHeaders, children, className }) => {
    return (React.createElement(StyledResponsive, { mb: mb, fixedHeaders: fixedHeaders },
        React.createElement(StyledTable, { className: className, truncateHeaders: truncateHeaders }, children)));
};
const StyledTable = styled.table `
  border-collapse: collapse;
  background-color: transparent;
  position: relative;
  table-layout: fixed;
  ${({ truncateHeaders }) => {
    if (truncateHeaders) {
        return css `
        th[scope='col'] {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }

        th[title] {
          cursor: help;
        }
      `;
    }
    return false;
}}
`;
const StyledResponsive = styled.figure `
  max-width: 100%;
  overflow-x: auto;
  margin: 0 0 ${({ mb }) => mb || '0'};
  /* margin-inline: -24px; */ /** for flush, TBC */
  ${({ fixedHeaders }) => {
    if (fixedHeaders) {
        return css `
        max-height: calc(100vh - 150px);
        th {
          position: sticky;
          top: 0px;
          z-index: 10;
          background-color: #fff;
        }
      `;
    }
    return false;
}}
`;
export const THead = styled.thead `
  border-bottom: 1px solid ${({ theme }) => theme.colors.light};
`;
export const Col = styled.col `
  ${({ width }) => width &&
    css `
      width: ${width};
    `}
  ${({ minWidth }) => minWidth &&
    css `
      min-width: ${minWidth};
    `}
  ${({ maxWidth }) => maxWidth &&
    css `
      max-width: ${maxWidth};
    `}
`;
export const TableHeader = styled.th.attrs((props) => (Object.assign({ scope: 'col' }, props))) `
  color: ${({ theme }) => theme.colors.dark};
  font-size: ${({ theme }) => theme.fontSizes.body2.mobile};
  line-height: ${({ theme }) => theme.lineHeights.body2.mobile};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-align: ${(props) => (props.format === 'numeric' ? 'right' : props.hAlign || 'left')};
  vertical-align: ${({ vAlign }) => vAlign || 'middle'};
  position: relative;
  padding: 1.6rem;
  ${({ width }) => width &&
    css `
      width: ${width};
    `}
  ${({ minWidth }) => minWidth &&
    css `
      min-width: ${minWidth};
    `}
  ${({ maxWidth }) => maxWidth &&
    css `
      max-width: ${maxWidth};
    `}

  @media ${screen.mediumUp} {
    padding: 1.6rem 2.4rem;
    font-size: ${({ theme }) => theme.fontSizes.body2.default};
    line-height: ${({ theme }) => theme.lineHeights.body2.default};
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    height: 3.2rem;
    ${({ border }) => border !== 'none' &&
    css `
        border-right: 1px solid ${({ theme }) => theme.colors.light};
      `}
  }
`;
export const TableSubheader = styled.th `
  color: ${({ theme }) => theme.colors.grey.dark};
  background-color: ${({ theme }) => theme.colors.background.medium};
  font-size: ${({ theme }) => theme.fontSizes.caption.mobile};
  line-height: ${({ theme }) => theme.lineHeights.caption.mobile};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  text-align: left;
  vertical-align: ${({ vAlign }) => vAlign || 'middle'};
  position: relative;
  padding: 1.6rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.light};
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  @media ${screen.mediumUp} {
    padding: 1.6rem 2.4rem;
    font-size: ${({ theme }) => theme.fontSizes.caption.default};
    line-height: ${({ theme }) => theme.lineHeights.caption.default};
  }
`;
export const TableRow = styled.tr `
  ${({ $noBorder, theme }) => !$noBorder &&
    `
    border-bottom: 1px solid ${theme.colors.light};
    &:last-child {
      border-bottom: none;
    }
  `}
`;
export const TableCell = styled.td `
  color: ${({ theme }) => theme.colors.dark};
  font-size: ${({ theme }) => theme.fontSizes.caption.default};
  line-height: ${({ theme }) => theme.lineHeights.caption.default};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  text-align: ${(props) => (props.format === 'numeric' ? 'right' : props.hAlign || 'left')};
  vertical-align: ${({ vAlign }) => vAlign || 'middle'};
  padding: 1.6rem;
  ${({ border }) => {
    if (border === 'left') {
        return css `
        border-left: 1px solid ${({ theme }) => theme.colors.light};
      `;
    }
    if (border === 'right') {
        return css `
        border-right: 1px solid ${({ theme }) => theme.colors.light};
      `;
    }
    return false;
}}

  @media ${screen.mediumUp} {
    padding: 1.6rem 2.4rem;
  }
`;
