import React from 'react'
import { useTheme } from 'styled-components'
import { Skeleton } from '@mui/material'
import {
  Alert,
  AlertTitle,
  AlertMessage,
  Body1,
  Span,
  IdeaIcon,
  Link,
  PaperplaneIcon,
  SpeechIcon,
} from '@tumelo/designsystem'
import { VotingPolicySelection } from '@tumelo/shared'
import { convertRecommendationToTidyResponse } from '../../application/services/Voting/utils'
import { VotingRecommendation } from '../../application/features/votingPolicies/types'
import { PayloadUnwrapperM } from '../Error/PayloadUnwrapper'
import { Payload } from '../../application/payload'

interface VotingRecommendationUIProps {
  votingPolicySelection: Payload<VotingPolicySelection> | undefined
  votingPolicyRecommendation: Payload<VotingRecommendation> | 'not-configured' | undefined
}

export const VotingRecommendationUI: React.FC<VotingRecommendationUIProps> = ({
  votingPolicySelection,
  votingPolicyRecommendation,
}) => {
  return (
    <PayloadUnwrapperM
      items={[votingPolicySelection, votingPolicyRecommendation]}
      customError={<VotingRecommendationError />}
      customNotInitialized={<VotingRecommendationLoading />}
      customSpinner={<VotingRecommendationLoading />}
    >
      {([votingPolicySelection, votingPolicyRecommendation]) => {
        if (votingPolicySelection === undefined) {
          return <VotingRecommendationNoPolicy />
        }
        if (
          votingPolicyRecommendation &&
          votingPolicyRecommendation !== 'not-configured' &&
          votingPolicyRecommendation !== undefined
        ) {
          return <VotingRecommendationAvailable votingPolicyRecommendation={votingPolicyRecommendation} />
        }
        return <VotingRecommendationUnavailable />
      }}
    </PayloadUnwrapperM>
  )
}

const VotingRecommendationLoading: React.FC = () => <SkeletonLoader />
const VotingRecommendationNoPolicy: React.FC = () => (
  <Alert mb="1.6rem">
    <AlertTitle icon={<IdeaIcon />}>Want to be heard on a greater scale?</AlertTitle>
    <AlertMessage mb="0">
      <Body1 mb="0">
        Set up a voting policy and the tech will do the rest. You can{' '}
        <Link href="/profile" colour="dark">
          set one up today
        </Link>
        .
      </Body1>
    </AlertMessage>
  </Alert>
)

const VotingRecommendationError: React.FC = () => (
  <Alert mb="1.6rem">
    <AlertTitle>Something went wrong...</AlertTitle>
    <AlertMessage mb="0">
      <Body1>
        We couldn&apos;t get vote policy details for this proposal. Try refreshing the page to try again.
        <br />
        Don&apos;t worry, you can still place your vote!
      </Body1>
      <Body1 mb="0">
        If the problem persists{' '}
        <a href="https://tumelo.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">
          get in touch
        </a>{' '}
        with our support team
      </Body1>
    </AlertMessage>
  </Alert>
)

interface VotingRecommendationAvailableProps {
  votingPolicyRecommendation: VotingRecommendation
}

const VotingRecommendationAvailable: React.FC<VotingRecommendationAvailableProps> = ({
  votingPolicyRecommendation,
}) => {
  const instruction = convertRecommendationToTidyResponse(votingPolicyRecommendation.recommendation.instruction)
  const theme = useTheme()
  return (
    <Alert mb="1.6rem">
      <AlertTitle icon={<PaperplaneIcon />}>Your voting policy...</AlertTitle>
      <AlertMessage mb="0">
        <Body1 mb="0">
          will still vote
          <Span color={theme.colors.secondary.medium} $semibold>
            {' '}
            {instruction}{' '}
          </Span>
          for you on this issue, unless you decide otherwise.
        </Body1>
      </AlertMessage>
    </Alert>
  )
}
const VotingRecommendationUnavailable: React.FC = () => (
  <Alert mb="1.6rem">
    <AlertTitle icon={<SpeechIcon />}>Your voting policy advisors are still discussing this!</AlertTitle>
    <AlertMessage mb="0">
      <Body1 mb="0">Your policy will place a vote for the AGM, if you don&apos;t vote in the meantime.</Body1>
    </AlertMessage>
  </Alert>
)

const SkeletonLoader = () => {
  return (
    <Alert mb="1.6rem">
      <div data-testid="loading">
        <Skeleton variant="rounded" />
      </div>
    </Alert>
  )
}
