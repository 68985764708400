import React from 'react'
import { ModalImage, H1, Body1, Span, ModalBody, ModalFooter, ArrowRightIcon } from '@tumelo/designsystem'

interface Props {
  goForward: () => void
}

export const PoliciesIntroductionOne: React.FC<Props> = ({ goForward }) => (
  <>
    <ModalBody fullScreen>
      <ModalImage src="/images/voting_policy_step_one.png" />
      <div>
        <H1>Make more impact</H1>
        <Body1 $semibold>
          Your money is invested in hundreds of companies; which means you have hundreds of opportunities to have your
          say on how they&apos;re run.
        </Body1>
        <Body1>But most of the time, those decisions are made for you - we think that should change.</Body1>
        <Body1>
          By setting up a <Span $semibold>voting policy</Span>, you can ensure that even on your busiest days, your
          money is working in line with your values.
        </Body1>
      </div>
    </ModalBody>
    <ModalFooter
      stickyButtons={[
        {
          label: "What's a voting policy?",
          variant: 'primary',
          iconAfter: <ArrowRightIcon />,
          onClick: goForward,
        },
      ]}
    />
  </>
)
