import React from 'react';
import styled, { css } from 'styled-components';
import { useBreakpointValues, VisuallyHidden } from '..';
// use named function to make it work in testing library tests when there are nested components and styled components
// https://github.com/styled-components/styled-components/issues/1449
export function Button({ children, type = 'button', variant = 'primary', size = 'medium', label, fullWidth = false, iconOnly, iconOnlyAltText, iconBefore, iconAfter, isDisabled = false, onClick, className, }) {
    return (React.createElement(StyledButton, { type: type, variant: variant, size: size, fullWidth: useBreakpointValues(fullWidth), iconOnly: iconOnly, iconBefore: iconBefore, iconAfter: iconAfter, disabled: isDisabled, onClick: onClick, className: className }, iconOnly ? (React.createElement(React.Fragment, null,
        iconOnly,
        iconOnlyAltText && React.createElement(VisuallyHidden, null, iconOnlyAltText))) : (React.createElement(React.Fragment, null,
        iconBefore,
        " ",
        label || children,
        " ",
        iconAfter))));
}
export const ButtonStyling = css `
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 2rem;
  border: none;
  border-radius: 5px;
  width: ${(p) => (p.fullWidth ? '100%' : 'auto')};
  min-width: ${(p) => (p.iconOnly ? 'auto' : '125px')};
  padding: ${(p) => (p.size === 'small' || p.iconOnly ? '0.8rem' : '1.6rem')};
  ${(p) => (p.iconAfter ? 'padding-right: 4.6rem;' : null)}
  ${(p) => (p.iconBefore ? 'padding-left: 4.6rem;' : null)}
  position: relative;
  display: inline-block;
  text-align: center;
  &:enabled {
    cursor: pointer;
  }
  ${(p) => (p.disabled ? 'pointer-events: none;' : null)}
  svg {
    vertical-align: middle;
    display: ${(p) => (p.iconOnly ? 'block' : 'initial')};
    ${(p) => (p.iconAfter ? 'position: absolute; top: 50%; transform: translateY(-50%); right: 1.6rem' : null)}
    ${(p) => (p.iconBefore ? 'position: absolute; top: 50%; transform: translateY(-50%); left: 1.6rem' : null)}
  }

  ${({ variant, disabled, theme, iconOnly, size, iconAfter, iconBefore }) => {
    const mainColour = disabled ? theme.colors.danger.light : theme.colors.secondary.medium;
    switch (variant) {
        case 'secondary':
            return css `
          color: ${mainColour};
          background-color: ${theme.colors.white};
          border: 2px solid ${mainColour};
          padding: ${size === 'small' || iconOnly ? '0.6rem' : '1.4rem'};
          ${iconAfter ? 'padding-right: 4.4rem;' : null}
          ${iconBefore ? 'padding-left: 4.4rem;' : null}


        svg {
            fill: ${mainColour};
            ${iconAfter ? 'right: 1.4rem;' : null}
            ${iconBefore ? 'left: 1.4rem;' : null}
          }

          &:enabled:hover,
          &:enabled:active {
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
          }
        `;
        case 'tertiary':
            return css `
          color: ${mainColour};
          background-color: ${theme.colors.white};

          svg {
            fill: ${mainColour};
          }

          &:enabled:hover,
          &:enabled:active {
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
          }
        `;
        default:
            return css `
          color: ${theme.colors.white};
          background-color: ${disabled ? theme.colors.danger.light : theme.colors.secondary.medium};

          svg {
            fill: ${theme.colors.white};
          }

          &:enabled:hover {
            background-color: ${theme.colors.secondary.light};
          }
          &:enabled:active {
            background: ${theme.colors.secondary.dark};
          }
          &:focus-visible {
            ${ButtonFocusStyling}
          }
        `;
    }
}}
`;
export const ButtonFocusStyling = css `
  outline: #000 2px solid;
  outline-offset: 3px;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 2px #fff;
`;
const StyledButton = styled.button.withConfig({
    shouldForwardProp: (prop) => !['variant', 'size', 'fullWidth', 'iconOnly', 'iconBefore', 'iconAfter'].includes(prop),
}) `
  ${ButtonStyling};
`;
