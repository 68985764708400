/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled, { useTheme } from 'styled-components'
import { BreakpointAsMaxValue } from '@tumelo/shared'

interface Props {
  primary: React.ReactNode
  secondary: React.ReactNode
  primaryBackground?: string
  secondaryBackground?: string
  /**
   By default the order is <primary> | <secondary> on desktop and
   on mobile:
   -----
   primary
   -----
   secondary
   -----
   if reversed, on mobile the order will be secondary first then primary
   */
  reverseOrderOnMobile?: boolean
  hideSecondaryOnMobile?: boolean
  primaryPercentage?: number
  primaryPercentageMobile?: number
  primaryAs?: AsOptions
  secondaryAs?: AsOptions
  header?: React.ReactNode
}

type AsOptions = 'main' | 'aside' | 'div'

export const SplitLayout: React.FC<Props> = ({
  primary,
  secondary,
  primaryBackground,
  secondaryBackground,
  reverseOrderOnMobile = false,
  hideSecondaryOnMobile = false,
  primaryPercentage = 50,
  primaryPercentageMobile = primaryPercentage,
  primaryAs = 'div',
  secondaryAs = 'div',
  header,
}) => {
  const { colors } = useTheme()
  return (
    <Container>
      <StyledWrapper>
        {header}
        <Column
          background={primaryBackground ?? colors.white}
          order={reverseOrderOnMobile ? 1 : 0}
          size={primaryPercentage}
          sizeMobile={hideSecondaryOnMobile ? 100 : primaryPercentageMobile}
          zIndex={1}
          as={primaryAs}
          boxShadow
        >
          {primary}
        </Column>
      </StyledWrapper>
      <Column
        background={secondaryBackground ?? colors.white}
        hiddenOnMobile={hideSecondaryOnMobile}
        order={0}
        size={100 - primaryPercentage}
        sizeMobile={100 - primaryPercentageMobile}
        as={secondaryAs}
      >
        {secondary}
      </Column>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: ${BreakpointAsMaxValue.desktop}) {
    flex-direction: column;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`
interface ColumnProps {
  background: string
  order: number
  size: number
  sizeMobile: number
  hiddenOnMobile?: boolean
  zIndex?: number
  boxShadow?: boolean
  justify?: string
}

const Column = styled.div<ColumnProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: ${(p) => p.justify || 'start'};
  overflow-wrap: anywhere;
  padding: 1rem;
  // flex: 0 0 ${(p) => p.size}%;
  // z-index: ${(p) => (p.zIndex ? p.zIndex : 0)};
  box-shadow: ${(p) => (p.boxShadow ? '0 0 20px 0 rgba(0, 0, 0, 0.16)' : 'none')};
  background: ${(p) => p.background};
  @media (max-width: ${BreakpointAsMaxValue.desktop}) {
    display: ${(p) => (p.hiddenOnMobile ? 'none' : 'flex')};
    // flex: 0 0 ${(p) => p.sizeMobile}%;
    order: ${(p) => p.order};
    box-shadow: none;
  }
`
