/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import { Body, LinkButton } from '@tumelo/shared'
import styled, { useTheme } from 'styled-components'

interface Props {
  onClick: () => void
  /**
   * firstPart is not clickable and not highlighted
   */
  firstPart: string
  /**
   * secondPart is clickable and highlighted
   */
  secondPart: string
}

/**
 * AuthBottomButton provides a link to other pages in the auth space
 */
export const AuthBottomButton: React.FC<Props> = ({ onClick, firstPart, secondPart }) => {
  const { colors } = useTheme()
  return (
    <SignInSection>
      <Body m={0}>
        {firstPart}
        <LinkButton type="button" onClick={onClick} color={colors.secondary.light}>
          {secondPart}
        </LinkButton>
      </Body>
    </SignInSection>
  )
}

const SignInSection = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  width: 100%;
  padding-top: 2rem;
`
