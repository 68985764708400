/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import { Body, FontWeight, Heading } from '@tumelo/shared'
import { useTheme } from 'styled-components'

interface Props {
  heading: string
  subHeading: string | React.ReactElement
}

/**
 * AuthHeader is a standard header to be used in auth components.
 */
export const AuthHeader: React.FC<React.PropsWithChildren<Props>> = ({ heading, subHeading }) => {
  const { colors } = useTheme()
  return (
    <>
      <Heading as="h1" mb={2} mt="1.3rem" color={colors.dark} fontWeight={FontWeight.semiBold}>
        {heading}
      </Heading>
      <Body fontWeight={FontWeight.normal} marginTop={0} marginBottom={2}>
        {subHeading}
      </Body>
    </>
  )
}
