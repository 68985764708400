import React from 'react';
import { useRadioGroup } from '@mui/material';
import styled, { useTheme } from 'styled-components';
import { buttonStyles, ButtonContent } from './Button';
export const RadioSelectButton = ({ label, value }) => {
    var _a;
    const radioGroup = useRadioGroup();
    const { colors } = useTheme();
    if (!radioGroup) {
        return null;
    }
    const checked = radioGroup.value === value;
    const id = ((_a = radioGroup.name) !== null && _a !== void 0 ? _a : '').concat(value).trim();
    const [backgroundColor, fontColor] = checked
        ? [colors.secondary.medium, colors.white]
        : [colors.white, colors.secondary.medium];
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledInput, { id: id, name: radioGroup.name, value: value, checked: checked, onChange: (e) => radioGroup.onChange(e, e.target.value) }),
        React.createElement(StyledLabel, { size: "large", backgroundColor: backgroundColor, disabled: false, inverse: false, fullWidth: false, iconAligned: "left", htmlFor: id },
            React.createElement(ButtonContent, { iconAligned: "left", fontColor: fontColor }, label))));
};
const StyledInput = styled.input.attrs({ type: 'radio' }) `
  opacity: 0;
  position: fixed;
  width: 0;
  &:focus + label {
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colors.success.dark};
  }
`;
const StyledLabel = styled.label `
  line-height: 0;
  ${buttonStyles}
`;
