/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled, { useTheme } from 'styled-components'
import { isPollEndingSoon, Poll, Caption, timestampToDate } from '@tumelo/shared'
import { ClockIcon } from '@tumelo/designsystem'
import { daysRemaining } from '../../../utils/date'
import { TotalVotesContainer } from './TotalVotes'

interface Props {
  poll: Poll
  showVoteCount?: boolean
}

export const DaysRemaining: React.FC<Props> = ({ poll, showVoteCount = true }) => {
  const days = daysRemaining(timestampToDate(poll.endDate))

  const isEndingSoon = isPollEndingSoon(poll)
  const { colors } = useTheme()
  const color = isEndingSoon ? colors.danger.medium : colors.primary.medium

  return (
    <Container>
      {showVoteCount && <TotalVotesContainer poll={poll} />}
      <Container>
        <Clock fill={color} />
        <Caption color={color} data-testid="days-remaining">
          <b>{days} </b>
          {days === 1 ? 'day' : 'days'} left
        </Caption>
      </Container>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 5.5rem;
`

const Clock = styled(ClockIcon)`
  margin-right: 0.7rem;
`
