import * as React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { CallToAction } from '../CallToAction';
export const Card = ({ children, width = '46rem', onClick, className, }) => {
    return (React.createElement(CardContainer, { onClick: onClick, clickable: !!onClick, width: width, className: className }, children));
};
export const CardContainer = styled.div `
  display: flex;
  flex-direction: column;
  max-width: ${(p) => p.width};
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 2rem;
  margin-bottom: 3rem;
  outline: none;
  overflow: hidden;
  ${({ clickable }) => clickable && 'cursor: pointer;'}

  &:hover {
    box-shadow: 0 0 1.3rem 0 rgba(0, 0, 0, 0.16);
  }
`;
export const CardCTA = styled(CallToAction) `
  margin: 3rem;
`;
export const CardContent = styled.div `
  margin: 1rem 3rem;
  ${space}
  p:first-of-type {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 3rem;
  }
`;
