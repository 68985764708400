import React from 'react'
import styled, { keyframes, useTheme } from 'styled-components'
import { SearchIcon, MegaphoneIcon, Body1 } from '@tumelo/designsystem/'
import { SplitLayout } from '../../Layout/SplitLayout/SplitLayout'
import TopBarSimple from '../../Layout/TopBarSimple/TopBarSimple'

/**
 * AuthSplitContainer is a visual container to provide some context to the auth pages.
 */
export const AuthSplitContainer: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { colors } = useTheme()

  return (
    <Container>
      <SplitLayout
        hideSecondaryOnMobile
        primary={<FormContainer>{children}</FormContainer>}
        primaryBackground={colors.white}
        secondaryBackground={colors.light}
        secondary={<SecondaryContent />}
        primaryPercentage={50}
        primaryAs="main"
        secondaryAs="aside"
        header={<TopBarSimple />}
      />
    </Container>
  )
}

const SecondaryContent: React.FC = () => {
  return (
    <StyledSecondary>
      <StyledOrb width="400px" height="400px" scale={0.95} bottom="-3%" right="-3%" opacity="0.04" />
      <StyledOrb
        width="290px"
        height="290px"
        scale={0.9}
        bottom="17%"
        left="8%"
        delay="1s"
        opacity="0.03"
        gradientTo="#48678b"
      />
      <StyledOrb width="34px" height="34px" scale={0.6} top="10%" left="10%" delay="0.5s" />
      <StyledOrb width="57px" height="57px" scale={0.8} top="40%" left="1%" delay="1s" />
      <StyledOrb width="38px" height="38px" scale={0.7} top="20%" right="10%" delay="2s" />
      <StyledFlexCol>
        <StyledImg src="/images/phone.png" alt="" />
        <StyledFlexRow>
          <StyledFlexWidths>
            <SearchIcon fill="#fff" width="34px" height="34px" />
            <Body1 color="#fff">
              <b>See</b> where your pension money is invested
            </Body1>
          </StyledFlexWidths>
          <StyledFlexWidths>
            <MegaphoneIcon fill="#fff" width="34px" height="34px" />
            <Body1 color="#fff">
              <b>Vote</b> on issues at the companies you&apos;re invested in
            </Body1>
          </StyledFlexWidths>
        </StyledFlexRow>
      </StyledFlexCol>
    </StyledSecondary>
  )
}

const StyledSecondary = styled.div`
  object-fit: contain;
  background-image: linear-gradient(22deg, #242640 8%, #48678b 40%, #a4bacf 90%);
  display: flex;
  flex: 1;
  margin: -1rem;
  padding: 1rem;
  position: relative;
  overflow: hidden;
`
interface AnimationProps {
  width: string
  height: string
  scale: number
  opacity?: string
  gradientTo?: string
  top?: string
  bottom?: string
  left?: string
  right?: string
  delay?: string
  duration?: string
}
const scale = (value: number) => keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(${value});
  }
  100% {
    transform: scale(1);
  }
`
const StyledOrb = styled.div<AnimationProps>`
  background: #fff;
  opacity: ${(p) => p.opacity || '0.1'};
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  position: absolute;
  z-index: 1;
  top: ${(p) => p.top || 'auto'};
  bottom: ${(p) => p.bottom || 'auto'};
  right: ${(p) => p.right || 'auto'};
  left: ${(p) => p.left || 'auto'};
  background-image: linear-gradient(to bottom, #fff, ${(p) => p.gradientTo || '#fff'});
  border-radius: 9999px;
  animation-name: ${(p) => scale(p.scale)};
  animation-duration: ${(p) => p.duration || '6s'};
  animation-delay: ${(p) => p.delay || '0s'};
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`

const StyledFlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 1.5rem;
`
const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: flex-start;
  gap: 4rem;
`

const StyledFlexWidths = styled(StyledFlexCol)`
  flex-basis: 200px;
`
const StyledImg = styled.img`
  width: 300px;
  height: 499px;
  display: flex;
  margin-bottom: 2rem;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: center;
  max-width: 52rem;
`

const Container = styled.div`
  min-height: 100vh;
  display: flex;
`
