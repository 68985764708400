/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled, { useTheme } from 'styled-components'
import { format } from 'date-fns'
import { Caption, Breakpoint } from '@tumelo/shared'
import { ClockIcon } from '@tumelo/designsystem'

interface Props {
  resultsText: string
  endDate: Date
}

export const ResultsDue: React.FunctionComponent<Props> = ({ resultsText, endDate }) => {
  const countdownDate = format(endDate, 'do MMMM yyyy')
  const { dark } = useTheme().colors

  return (
    <Container>
      <DesktopClock />
      <div>
        <Text color={dark} weight="bold">
          <MobileClock />
          {countdownDate}
        </Text>
        <Result as="p" color={dark}>
          {resultsText}
        </Result>
      </div>
    </Container>
  )
}

const Clock = styled(ClockIcon)`
  margin: auto;
  margin-right: 1rem;
  @media (max-width: ${Breakpoint.tablet}) {
    margin: 0;
`

const DesktopClock = styled(Clock)`
  @media (max-width: ${Breakpoint.tablet}) {
    display: none;
  }
`

const MobileClock = styled(Clock)`
  display: none;
  padding-right: 1rem;
  vertical-align: middle;
  @media (max-width: ${Breakpoint.tablet}) {
    display: inline-block;
  }
`

const Text = styled(Caption)`
  margin: 0;
`

const Result = styled(Text)`
  max-width: 40rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${Breakpoint.tablet}) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`
