/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Collapse, IconButton } from '@mui/material'
import { CancelOutlined, HelpOutlineOutlined } from '@mui/icons-material'
import { Breakpoint, Body, SubHeading, headingFontSize } from '@tumelo/shared'

export interface Props {
  title: string | React.ReactElement
  helpText?: string | React.ReactNode
  className?: string // internal for styled components https://styled-components.com/docs/basics#styling-any-component
  /**
   * inheritBackgroundColo is a property that is set to inherit so that in ListContainer you can put a break through the
   * header.
   *
   * Defaults to inherit.
   *
   * TODO: We probably want to change this behaviour
   */
  backgroundColor?: 'transparent' | 'inherit'
}

export const HeadingWithHelp: React.FC<Props> = ({ title, helpText, className, backgroundColor = 'inherit' }) => {
  const [isOpen, setOpen] = React.useState(false)
  const { colors } = useTheme()
  return (
    <Row className={className} backgroundColorSetting={backgroundColor}>
      {title && (
        <Title backgroundColorSetting={backgroundColor}>
          <SubTitleContainer backgroundColorSetting={backgroundColor}>
            <SubHeading>{title}</SubHeading>
            {helpText && (
              <RemoveTopPadding>
                <StyledIconButton
                  title="Toggle help"
                  style={inheritFontSize}
                  disableRipple
                  onClick={() => setOpen(!isOpen)}
                >
                  {isOpen ? <CancelOutlined fontSize="inherit" /> : <HelpOutlineOutlined fontSize="inherit" />}
                </StyledIconButton>
              </RemoveTopPadding>
            )}
          </SubTitleContainer>
        </Title>
      )}
      {helpText && (
        <Help>
          <Collapse in={isOpen}>
            <Body as="p" color={colors.grey.dark}>
              {helpText}
            </Body>
          </Collapse>
        </Help>
      )}
    </Row>
  )
}

interface BackgroundColorProp {
  readonly backgroundColorSetting: 'inherit' | 'transparent'
}

const inheritFontSize = { fontSize: 'inherit' }

const RemoveTopPadding = styled.div`
  & button {
    padding-top: 0;
  }
`
const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.primary.medium};
  align-self: flex-start;
`

const Help = styled.div`
  order: 1;
  flex-basis: 100%;
  flex-grow: 1;
  @media (max-width: ${Breakpoint.tablet}) {
    order: 0;
  }
`

const Row = styled.div<BackgroundColorProp>`
  display: flex;
  width: 100%;
  background-color: ${({ backgroundColorSetting }) => backgroundColorSetting};
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  @media (max-width: ${Breakpoint.tablet}) {
    margin-bottom: 1rem;
  }
`

const SubTitleContainer = styled.div<BackgroundColorProp>`
  display: flex;
  align-items: baseline;
  background-color: ${({ backgroundColorSetting }) => backgroundColorSetting};
  z-index: 1;
  ${headingFontSize.SubHeading};
`

const Title = styled.div<BackgroundColorProp>`
  display: flex;
  background-color: ${({ backgroundColorSetting }) => backgroundColorSetting};
`
