/* eslint-disable @typescript-eslint/no-deprecated */
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Text, Caption } from '../../styles';
export const CallToAction = ({ callToAction, onClick, color, size = 'large', className, }) => {
    const { colors } = useTheme();
    const textColor = color || colors.primary.medium;
    return (React.createElement(Footer, { className: className },
        size === 'large' ? (React.createElement(LargeLink, { as: onClick ? 'a' : 'p', color: textColor, onClick: onClick }, callToAction)) : (React.createElement(SmallLink, { as: onClick ? 'a' : 'p', color: textColor, onClick: onClick }, callToAction)),
        React.createElement("svg", { fill: colors.secondary.medium, onClick: onClick, xmlns: "http://www.w3.org/2000/svg", id: "cardArrowIcon", width: "16", height: "13.517", viewBox: "0 0 16 13.517" },
            React.createElement("g", { id: "Layer_2", "data-name": "Layer 2", transform: "translate(0 -.012)" },
                React.createElement("g", { id: "Layer_1", "data-name": "Layer 1" },
                    React.createElement("g", { id: "Icon_Foward_arrow", "data-name": "Icon Foward arrow" },
                        React.createElement("path", { id: "icon", d: "M278.44 428.06l5.88 5.88a.76.76 0 0 1 .37.65.79.79 0 0 1-.1.37.76.76 0 0 1-.13.17l-.08.07-5.93 5.93a.75.75 0 1 1-1.06-1.06l4.73-4.73h-12.68a.75.75 0 0 1-.75-.75.75.75 0 0 1 .75-.75h12.66l-4.71-4.71a.746.746 0 1 1 1.05-1.06z", className: "cls-1", transform: "translate(-268.69 -427.84)" })))))));
};
const Container = styled.div ``;
const Footer = styled(Container) `
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const SmallLink = styled(Caption) `
  color: ${(p) => p.color};
  cursor: pointer;
  margin: 0 1rem 0 0;
  border-bottom: 1px solid ${(p) => p.color};
`;
const LargeLink = styled(Text) `
  color: ${(p) => p.color};
  cursor: pointer;
  margin: 0 1rem 0 0;
  border-bottom: 1px solid ${(p) => p.color};
`;
