import styled from 'styled-components'
import { Link } from '../../application/router/Link'

const BORDER_RADIUS = 20

const BaseContainer = styled.div`
  padding: 10px 20px;
`

export const CardletTop = styled(BaseContainer)`
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0;
  padding: 1.4rem 2rem 2rem;
  flex: 1;
`

export const CardletBottom = styled(BaseContainer)<{ displayShadow?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px;
  box-shadow: ${({ theme, displayShadow }) =>
    displayShadow || displayShadow === undefined ? `0 0 6px 0 ${theme.colors.boxShadow}` : 'none'};
`

export const BaseCardlet = styled.div`
  &:hover ${CardletTop} {
    background-color: ${({ theme }) => theme.colors.background.medium};
  }
  &:hover ${CardletBottom} {
    background-color: ${({ theme }) => theme.colors.background.medium};
  }
  &:hover {
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 32rem;
  box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.boxShadow};
  border-radius: 20px;
  position: relative;
`

export const PollTitle = styled(Link)`
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`

export const CardletRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const CardletColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 10rem;
`
